import SkyZaiLogo from "./home";


function App() {
  return (
    <div className="w-full h-screen">
      <SkyZaiLogo />
    </div>
  );
}

export default App;